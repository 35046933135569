import React from "react";
import { connect } from "react-redux";
import { detailActions, syncActions } from "../redux/actions";
import { apiService } from "../services";
import Moment from "moment";
import StatusSelect from "./controls/statusSelect";
import VisoCollapse from "./controls/viso_collapse";
import MetaDataCollapse from "./controls/metaDataCollapse";
import Image from "./controls/image";
import { getter } from "./helper";
import { pageState, roleAction } from "./enums";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import en from "../assets/en.json";
import de from "../assets/de.json";
import ActCommentImg from "../assets/comment.svg";
import DeactCommentImg from "../assets/d-comment.svg";
import ActHistoryImg from "../assets/history.svg";
import DeactHistoryImg from "../assets/d-history.svg";
import { getGeneralSvg, getTypeSvg } from "./controls/svg";
import ExpandableTextBlock from "./controls/expandableTextblock";
import IssueHistory from "./controls/issueHistory";
import "../css/detailIssue.css";

library.add(faArrowDown);

function localeDate(date) {
  return window.isGerman
    ? Moment(date).locale("de").format("DD.MMM")
    : Moment(date).locale("en").format("MMM DD");
}

function commentLocaleDate(date) {
  return window.isGerman
    ? Moment(date).locale("de").format("YYYY-MM-DD-HH:mm")
    : Moment(date).locale("en").format("DD/MM/YYYY-HH:mm");
}

function dueDate(date) {
  return window.isGerman
    ? Moment(date).locale("de").format("DD.MM.YYYY")
    : Moment(date).locale("en").format("DD/MM/YYYY");
}

class IssueDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      disciplines: [],
      viewpoints: [],
      elements: [],
      comboDeactivity: false,
      editable: false,
      isCommentCreatable: false,
      docVersions: [],
      buildings: [],
      floors: [],
      issueHistories: [],
      isComment: true,
      commentImg: DeactCommentImg,
      historyImg: DeactHistoryImg,
      tags: [],
      workPhaseName: undefined,
      linkedMails: [],
      mainTasks: [],
      subTasks: [],
      documents: [],
    };

    window.IssueDetail = this;
  }

  componentDidMount() {
    if (!this.props.issue.id) {
      this.props.onSetPage(pageState.ISSUELIST);
    } else {
      this.retrieveData();
      this.timerId = setInterval(() => {
        this.props.syncIssue(this.props.issue.id);
      }, 60000);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.issue !== prevProps.issue) {
      this.retrieveData();
    }

    if (this.props.viewpoints !== prevProps.viewpoints) {
      this.getViewpoints();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  retrieveData() {
    this.getMetaData();
    this.getComments();
    this.getViewpoints();
    this.requestElementNames();
    this.setActivity();
    this.setEditable();
    this.setCommentCreatable();
    this.getDocVersions();
    this.getIssueHistories();
    this.getTags();
    this.getWorkPhase();
    this.getMails();
    this.getTasks();
  }

  getWorkPhase() {
    if (this.props.project.workPhases) {
      const workPhase = this.props.project.workPhases.find(
        (wp) => wp.id === this.props.issue.workPhaseId
      );
      if (workPhase) this.setState({ workPhaseName: workPhase.name });
    }
  }

  getTags() {
    if (this.props.project.tags) {
      const tags = this.props.project.tags.filter((t) =>
        this.props.issue.tags.includes(t.id)
      );
      this.setState({ tags });
    } else {
      this.setState({ tags: [] });
    }
  }

  getMails() {
    const linkedMails = this.props.emails.filter((e) =>
      this.props.issue.linkedEmailIds.includes(e.id)
    );
    this.setState({ linkedMails });
  }

  getTasks() {
    if (!!this.props.issue.parentId) {
      apiService
        .getArrayOData("issue", [this.props.issue.parentId])
        .then((res) => {
          this.setState({ mainTasks: res });
        })
        .catch((err) => {
          console.log("Dont get main task", err.message);
        });
    } else {
      this.setState({ mainTasks: [] });
    }

    if (
      !!this.props.issue.childrenIds &&
      this.props.issue.childrenIds.length !== 0
    ) {
      apiService
        .getArrayOData("issue", this.props.issue.childrenIds)
        .then((res) => {
          this.setState({ subTasks: res });
        })
        .catch((err) => {
          console.log("Dont get sub task", err.message);
        });
    }
  }

  async getIssueHistories() {
    const logId = this.props.issue.issueLogId;
    if (logId) {
      await apiService
        .getArrayData("issue/issuelog", [logId])
        .then((logEntries) => {
          const issueHistories = logEntries[0].issueLogEntries.sort((a, b) => {
            return new Date(b.creationDate) - new Date(a.creationDate);
          });

          this.setState({ issueHistories: issueHistories });
        })
        .catch((err) => {
          console.log("Can't get the issue history.", err.message);
        });
    }
  }

  getUserName(userId) {
    const user = this.props.project.userPreviews.find((u) => u.id === userId);
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }

    return "";
  }

  async getDocVersions() {
    const docVersionIds = this.props.issue.linkedDocumentVersionIds;
    await apiService
      .getArrayOData("documentVersion", docVersionIds)
      .then(async (docVersions) => {
        const documents = [...docVersions];
        const docIds = docVersions.map((v) => v.documentId);
        await apiService.getArrayData("document", docIds).then((docs) => {
          const docVersionData = docVersionIds
            .map((id) => {
              const doc = docs.find((d) => d.documentVersionIds.includes(id));
              return { versionId: id, document: doc };
            })
            .filter((d) => d.document);

          this.setState({ docVersions: docVersionData, documents });
        });
      })
      .catch((err) => {
        console.log("Can't get the document versions.", err.message);
      });
  }

  getMetaData() {
    const disciplineMetaData = this.props.disciplines;
    const disciplines = disciplineMetaData
      .filter((d) => this.props.issue.disciplines.includes(d.id))
      .map((d) => {
        return d.abbreviation;
      });

    const buildingMetaData = this.props.buildings;
    const buildings = buildingMetaData
      .filter((b) => this.props.issue.buildings.includes(b.id))
      .map((b) => {
        return b.abbreviation;
      });

    const floorMetaData = this.props.floors;
    const floors = floorMetaData
      .filter((f) => this.props.issue.floors.includes(f.id))
      .map((f) => {
        return f.abbreviation;
      });

    this.setState({
      disciplines: disciplines,
      buildings: buildings,
      floors: floors,
    });
  }

  getComments() {
    this.props.getComments(this.props.issue.commentIds);
  }

  getViewpoints() {
    let vps = this.props.viewpoints
      .filter((v) => this.props.issue.viewpointIds.includes(v.id))
      .filter((v) => v.deleted === false);
    this.setState({ viewpoints: vps });
  }

  requestElementNames() {
    let ids = this.props.issue.linkedComponentsGlobalIds;
    ids = [...new Set(ids)];
    if (ids.length > 0) {
      window.GetElementNames(ids);
    }
  }

  registerElementNames(elemInfos) {
    const names = elemInfos.map((e) => {
      const jsValue = JSON.parse(e);
      return jsValue.name;
    });
    this.setState({ elements: names });
  }

  setActivity() {
    const activity = !this.getActivity();
    this.setState({ comboDeactivity: activity });
  }

  getActivity() {
    const { issue, user } = this.props;
    if (issue.issueStatus?.order === 4) {
      return this.isActionAllowed(
        roleAction.IssueManagement_StatusEditing_UnsetClosed
      );
    }

    if (this.isActionAllowed(roleAction.IssueManagement_StatusEditing))
      return true;

    if (
      issue.createAuthorId === user.id &&
      this.isActionAllowed(roleAction.IssueManagement_Creator_StatusEditing)
    )
      return true;

    if (
      issue.reviewerId === user.id &&
      this.isActionAllowed(roleAction.IssueManagement_Reviewer_StatusEditing)
    )
      return true;

    if (
      issue.assignedCollaboratorIds.includes(user.id) &&
      this.isActionAllowed(roleAction.IssueManagement_Assigned_StatusEditing)
    )
      return true;

    return false;
  }

  isActionAllowed(action) {
    const actions = getter.getRoleActions(this.props.project, this.props.user);
    if (actions) {
      const role = actions.find((r) => r.action === action);
      return role.isAllowed;
    }

    return false;
  }

  setEditable() {
    const editable = !this.getEditable();
    this.setState({ editable: editable });
  }

  getEditable() {
    const { issue, user } = this.props;
    if (issue.issueStatus?.order === 4) return false;

    if (this.isActionAllowed(roleAction.IssueManagement_Editing)) return true;

    if (
      issue.createAuthorId === user.id &&
      this.isActionAllowed(roleAction.IssueManagement_Creator_Editing)
    )
      return true;

    if (
      issue.reviewerId === user.id &&
      this.isActionAllowed(roleAction.IssueManagement_Reviewer_Editing)
    )
      return true;

    if (
      issue.assignedCollaboratorIds.includes(user.id) &&
      this.isActionAllowed(roleAction.IssueManagement_Assigned_Editing)
    )
      return true;

    return false;
  }

  setCommentCreatable() {
    const creatable = !this.isActionAllowed(
      roleAction.IssueManagement_CreatingComments
    );
    this.setState({ isCommentCreatable: creatable });
  }

  handleGoBack = () => {
    this.props.onSetPage(pageState.ISSUELIST);
  };

  changeStatus = (status) => {
    if (this.props.issue.issueStatus === status.id) return;
    const issueDto = {
      id: this.props.issue.id,
      status: { value: status.id },
    };
    this.props.updateStatus([issueDto]);
  };

  changeComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  sendComment = () => {
    if (this.state.comment) {
      const commentDto = {
        IssueId: { value: this.props.issue.id },
        Text: { value: this.state.comment },
      };
      this.props.sendComment([commentDto]);
      this.setState({ comment: "" });
    }
  };

  selectViewpoint = (vId) => {
    const viewpoint = this.state.viewpoints.find((v) => v.id === vId);
    if (viewpoint) {
      window.SelectViewpoint(this.makeApiViewpointData(viewpoint));
    }
  };

  makeApiViewpointData(viewpoint) {
    let apiVp = {};
    apiVp.cameraType = viewpoint.cameraType;
    apiVp.location = viewpoint.location;
    apiVp.direction = viewpoint.direction;
    apiVp.up = viewpoint.up;
    apiVp.cameraFactor = viewpoint.cameraFactor;
    apiVp.visibility = viewpoint.visibility;
    apiVp.selectedGlobalIds = viewpoint.visualStates
      ? viewpoint.visualStates.selectedGlobalIds
      : [];
    apiVp.visbilityExceptionGlobalIds = viewpoint.visualStates
      ? viewpoint.visualStates.visbilityExceptionGlobalIds
      : [];
    apiVp.sections = viewpoint.sections ? viewpoint.sections : [];

    return apiVp;
  }

  editIssue = () => {
    this.props.onSetPage(pageState.EDITISSUE);
  };

  handleDownloadDocument = async (docData) => {
    await apiService
      .downloadDocumentVersion([docData.versionId])
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.download = docData.document.name;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log("Can't download the document.", err.message);
      });
  };

  handleClickComment = () => {
    this.setState({ isComment: true, historyImg: DeactHistoryImg });
  };

  handleClickHistory = () => {
    this.setState({ isComment: false, commentImg: DeactCommentImg });
  };

  handleSetCommentImg = (img) => {
    this.setState({ commentImg: img });
  };

  handleSetHistoryImg = (img) => {
    this.setState({ historyImg: img });
  };

  getStatuses() {
    return this.props.statuses.map((s) => {
      return { value: s, label: s.name };
    });
  }

  onClickTask = (e, task) => {
    this.props.openIssue(task);
  };

  renderComments() {
    return this.props.comments.map((comment) => {
      return (
        <div className="comment_body">
          <div className="comment_title">
            <div>{`${comment.createAuthor?.firstName} ${comment.createAuthor?.lastName}`}</div>
            <div>{commentLocaleDate(comment.creationDate)}</div>
          </div>
          <div style={{ padding: "0 10px 5px 10px" }}>{comment.text}</div>
        </div>
      );
    });
  }

  renderViewpoints() {
    return this.state.viewpoints.map((viewpoint) => {
      const imageId = viewpoint.thumbnailImageId || viewpoint.imageId;
      return (
        <Image
          id={imageId}
          selectable={true}
          vId={viewpoint.id}
          selectViewpoint={() => this.selectViewpoint(viewpoint.id)}
        />
      );
    });
  }

  renderElements() {
    return this.state.elements.map((e) => {
      return <div style={{ cursor: 'pointer' }} onClick={() => window.ZoomToElement([e])}>{e}</div>;
    });
  }

  renderDocuments() {
    return this.state.docVersions.map((e) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 20px 0 0",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ margin: "0 5px" }}>{e.document.name}</span>
          </div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.handleDownloadDocument(e)}
          >
            {getGeneralSvg("download")}
          </span>
        </div>
      );
    });
  }

  renderAssignedUsers() {
    return this.props.issue.assignedUsers.map((u) => {
      return <div>{`${u.firstName} ${u.lastName}`}</div>;
    });
  }

  renderModels() {
    return this.props.issue.models.map((m) => {
      return <div>{m}</div>;
    });
  }

  renderHistories() {
    const { project, disciplines, buildings, floors } = this.props;
    const users = {};

    project.userPreviews.forEach((u) => {
      users[u.id] = `${u.firstName} ${u.lastName}`;
    });

    return this.state.issueHistories.map((history) => {
      return (
        <IssueHistory
          log={history}
          users={users}
          documents={this.state.documents}
          disciplines={getMetaDataMap(disciplines)}
          buildings={getMetaDataMap(buildings)}
          floors={getMetaDataMap(floors)}
        />
      );
    });

    function getMetaDataMap(metadata) {
      const mapData = {};
      metadata.forEach((m) => {
        mapData[m.id] = m.name;
      });

      return mapData;
    }
  }

  renderTags() {
    const tag = this.state.tags[0];
    const count = this.state.tags.length - 1;
    return (
      <>
        <div
          className="tag-container"
          style={{ color: `${tag.color}`, background: `${tag.color}30` }}
        >
          {tag.name}
        </div>
        {count > 0 && (
          <div
            className="tag-container"
            style={{ color: "#2149E8", background: "#D5E1FF" }}
          >{`+${count}`}</div>
        )}
      </>
    );
  }

  renderEmails() {
    return this.state.linkedMails.map((mail) => {
      return (
        <div className="content_title">
          <label className="text-ellipsis" style={{ width: "45%" }}>
            {mail.subject}
          </label>
          <label
            className="text-ellipsis"
            style={{ width: "45%" }}
          >{`Send by ${mail.from}`}</label>
        </div>
      );
    });
  }

  renderTasks(tasks) {
    return tasks.map((t) => {
      return (
        <div className="content_title" style={{ margin: "1px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                background: `${t.issueType?.color}30`,
                width: "24px",
                height: "24px",
                borderRadius: "3px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {getTypeSvg(t.issueType?.icon, t.issueType?.color)}
            </div>
            <label
              style={{
                margin: "0 3px",
                color: "#4B6FFC",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => this.onClickTask(e, t)}
            >
              {t.title}
            </label>
          </div>
          {!!t.dueDate && <div>{`Due to ${dueDate(t.dueDate)}`}</div>}
        </div>
      );
    });
  }

  render() {
    const lang = window.isGerman ? de : en;
    const { issue, comments, project } = this.props;
    const {
      comment,
      disciplines,
      viewpoints,
      elements,
      comboDeactivity,
      editable,
      isCommentCreatable,
      docVersions,
      buildings,
      floors,
      issueHistories,
      isComment,
      commentImg,
      historyImg,
      tags,
      workPhaseName,
      linkedMails,
      mainTasks,
      subTasks,
    } = this.state;
    const dueDate = `${lang["Due"]} ${
      issue.dueDate ? localeDate(issue.dueDate) : ""
    }`;
    const startDate = `Start ${
      issue.startingDate ? localeDate(issue.startingDate) : ""
    }`;
    const statuses = this.getStatuses();
    const issueStatus = statuses.find(s => issue.issueStatus === s.value.id)?.value;

    return (
      <div className="detail_container">
        <div className="detail_header">
          <h2>{lang["Issues"]}</h2>
          <div className="rightSide">
            <label>{project.name}</label>
          </div>
        </div>
        <div className="detail_back">
          <label>{"<"}</label>
          <button
            className="transparent_button"
            style={{ textDecoration: "underline" }}
            onClick={this.handleGoBack}
          >
            {lang["Back"]}
          </button>
        </div>
        <div className="detail_blank" />
        <div className="detail_body scrollbar">
          <div className="detail_body_header">
            <div className="content_title" style={{ margin: "8px 0" }}>
              <button
                className={
                  editable ? "visoplan_button_disable" : "visoplan_button"
                }
                style={{ width: "160px" }}
                disabled={editable || issueStatus.name === "Closed"}
                onClick={this.editIssue}
              >
                {lang["EditIssue"]}
              </button>
              <div style={{ minWidth: "100px" }}>
                <StatusSelect
                  options={statuses}
                  defaultValue={issueStatus}
                  onChange={this.changeStatus}
                  disabled={comboDeactivity}
                />
              </div>
            </div>
            <div className="content_title">
              <div style={{ display: "flex" }}>
                {tags.length > 0 && this.renderTags()}
              </div>
              <div style={{ whiteSpace: "nowrap" }}>
                <label>{"Source: "}</label>
                <label className="ellipsis">{issue.source}</label>
              </div>
            </div>
          </div>
          <div className="detail_body_content">
            <div>
              <div className="content_title">
                <div
                  className="content_title_header"
                  style={{
                    color: issue.issueType?.color,
                    background: `${issue.issueType?.color}30`,
                    height: "24px",
                    borderRadius: "3px",
                  }}
                >
                  {getTypeSvg(issue.issueType?.icon, issue.issueType?.color)}
                  <label style={{ margin: "0 5px" }}>
                    {issue.issueType?.name}
                  </label>
                </div>
                <div className="content_title_header">
                  <label
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      margin: "0 5px",
                    }}
                  >
                    {startDate}
                  </label>
                  <label style={{ whiteSpace: "nowrap" }}>{dueDate}</label>
                </div>
              </div>
              <div
                className="ellipsis"
                style={{ fontWeight: "bold" }}
              >{`${issue.issueNumber} ${issue.title}`}</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label>{`${lang["Workphase"]} ${
                  workPhaseName ? workPhaseName : ""
                }`}</label>
                <div className="item_line" style={{ margin: "0 5px" }}></div>
                <label>{`${lang["Priority"]} ${issue.issuePriority?.name}`}</label>
              </div>
              <div className="splitter" />
            </div>
            <div>
              <VisoCollapse title={lang["Explanation"]}>
                <ExpandableTextBlock content={issue.description} />
              </VisoCollapse>
              <div className="splitter" />
            </div>
            <div>
              <MetaDataCollapse
                disciplines={disciplines}
                buildings={buildings}
                floors={floors}
                defaultState={false}
              />
              <div className="splitter" />
            </div>
            <div>
              <VisoCollapse
                title={lang["AssignedTo"]}
                count={issue.assignedUsers.length}
                keyString="Collaborator"
              >
                <div>{issue.assignedUsers && this.renderAssignedUsers()}</div>
              </VisoCollapse>
              <div className="splitter" />
            </div>
            <div>
              <VisoCollapse
                title={lang["Viewpoints"]}
                count={viewpoints.length}
                keyString="Viewpoint"
              >
                <div className="viewpoint_list scrollbar">
                  {viewpoints && this.renderViewpoints()}
                </div>
              </VisoCollapse>
              <div className="splitter" />
            </div>
            <div>
              <VisoCollapse
                title={lang["Elements"]}
                count={elements.length}
                keyString="Element"
              >
                <div className="element_list scrollbar">
                  {elements && this.renderElements()}
                </div>
              </VisoCollapse>
              <div className="splitter" />
            </div>
            <div>
              <VisoCollapse
                title={lang["BimModels"]}
                count={issue.models.length}
                keyString="BimModel"
              >
                {issue.models && this.renderModels()}
              </VisoCollapse>
              <div className="splitter" />
            </div>
            <div>
              <VisoCollapse
                title={lang["LinkedDocuments"]}
                count={docVersions.length}
                keyString="Document"
              >
                {docVersions && this.renderDocuments()}
              </VisoCollapse>
              <div className="splitter" />
            </div>
            <div>
              <VisoCollapse
                title={lang["LinkedEmails"]}
                count={linkedMails.length}
                keyString="Mail"
              >
                {linkedMails && this.renderEmails()}
              </VisoCollapse>
              <div className="splitter" />
            </div>
            <div style={{ background: "#FAFAFA", padding: "10px" }}>
              <label style={{ fontWeight: "bold" }}>
                {lang["LinkedTasks"]}
              </label>
              <div style={{ margin: "5px 0" }}>
                <label>{lang["MainTask"]}</label>
                {this.renderTasks(mainTasks)}
              </div>
              <div>
                <label>{lang["SubTasks"]}</label>
                {this.renderTasks(subTasks)}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                }}
              >
                <div style={{ padding: "5px 0", fontWeight: "bold" }}>
                  {isComment ? lang["Comments"] : lang["Histories"]}
                </div>
                <div>
                  {isComment ? (
                    <img
                      style={{ margin: "0 5px" }}
                      src={ActCommentImg}
                      alt="comment button"
                    />
                  ) : (
                    <img
                      style={{ margin: "0 5px" }}
                      src={commentImg}
                      alt="comment button"
                      onClick={this.handleClickComment}
                      onMouseEnter={() =>
                        this.handleSetCommentImg(ActCommentImg)
                      }
                      onMouseLeave={() =>
                        this.handleSetCommentImg(DeactCommentImg)
                      }
                    />
                  )}
                  {!isComment ? (
                    <img src={ActHistoryImg} alt="history button" />
                  ) : (
                    <img
                      src={historyImg}
                      alt="history button"
                      onClick={this.handleClickHistory}
                      onMouseEnter={() =>
                        this.handleSetHistoryImg(ActHistoryImg)
                      }
                      onMouseLeave={() =>
                        this.handleSetHistoryImg(DeactHistoryImg)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="comment_list scrollbar">
                {isComment && comments && this.renderComments()}
                {!isComment && issueHistories && this.renderHistories()}
              </div>
            </div>
          </div>
          <div className="detail_body_footer">
            <input
              type="text"
              className="send_comment"
              id="comment"
              disabled={isCommentCreatable}
              value={comment}
              onChange={this.changeComment}
            ></input>
            <button
              className={
                isCommentCreatable
                  ? "visoplan_button_disable"
                  : "visoplan_button"
              }
              disabled={isCommentCreatable}
              onClick={this.sendComment}
            >
              {lang["Send"]}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    issue: state.detail.selectedIssue,
    comments: state.comment.comments,
    viewpoints: state.viewpoint.viewpoints,
    project: state.project.selectedProject,
    user: state.user.currentUser,
    disciplines: state.metaData.disciplines,
    buildings: state.metaData.buildings,
    floors: state.metaData.floors,
    statuses: state.issueDescriptor.statuses,
    types: state.issueDescriptor.types,
    priorities: state.issueDescriptor.priorities,
    emails: state.email.emails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getComments: (ids) => {
      dispatch(detailActions.getComments(ids));
    },
    sendComment: (data) => {
      dispatch(detailActions.sendComment(data));
    },
    updateStatus: (data) => {
      dispatch(detailActions.updateStatus(data));
    },
    syncIssue: (id) => {
      dispatch(syncActions.syncIssue(id));
    },
    openIssue: (issue) => {
      dispatch(detailActions.selectedIssue(issue));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetail);
