import React, { createRef } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { converters, getter } from "./helper";
import VisoSelect from "./controls/viso_select";
import { apiService } from "../services";
import { XMLParser } from "fast-xml-parser";
import en from "../assets/en.json";
import de from "../assets/de.json";
import "../css/exportModel.css";
import CreateNameSelect from "./controls/CreateNameSelect";
import { TreeSelect } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-regular-svg-icons";
import { roleAction } from "./enums";

class ExportModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      fileNames: [],
      isOpenModal: false,
      disciplines: [],
      buildings: [],
      floors: [],
      discipline: "",
      building: "",
      floor: "",
      isOnlyVisibleElement: false,
      isSetName: true,
      isSetDiscipline: true,
      isSetBuilding: true,
      isSetFloor: true,
      exporting_ifc: false,
      uploadHandle: "",
      isChecked: false,
      isIfcMapping: false,
      mappingFilePath: "",
      mappingData: undefined,
      treeData: [],
      folder: undefined,
      modelHistories: [],
      isExportEnable: false,
      checkResult: undefined,
      isAutoImport: false,
    };

    this.nameRef = createRef();

    this.openExportModal = this.openExportModal.bind(this);
    this.closeExportModal = this.closeExportModal.bind(this);
    this.handleChangeDiscipline = this.handleChangeDiscipline.bind(this);
    this.handleChangeBuilding = this.handleChangeBuilding.bind(this);
    this.handleChangeFloor = this.handleChangeFloor.bind(this);
    this.handleClickCheck = this.handleClickCheck.bind(this);
    window.ExportModel = this;
  }

  componentDidMount() {
    this.requestFileName();
    this.requestExportHistory();
    this.initialize();

    // #TODO use signalR or find other solution.
    // this.createInterval();
  }

  componentWillUnmount() {
    const { isIfcMapping, mappingFilePath, mappingData, modelHistories } =
      this.state;
    window.mappingInfo = { isIfcMapping, mappingFilePath, mappingData };
    if (modelHistories.length) {
      window.saveExportHistory({
        projectId: this.props.project.id,
        data: JSON.stringify(modelHistories),
        isModel: true,
      });
    }

    this.disposeInterval();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.folder !== this.state.folder) {
      if (!!this.state.folder) {
        this.getFileNamesInFolder(this.state.folder);
      }
    }
  }

  createInterval() {
    this.timerId = setInterval(() => {
      this.getFolderData();
    }, 60000);
  }

  disposeInterval() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  requestExportHistory() {
    window.requestExportHistory({
      projectId: this.props.project.id,
      model: true,
    });
  }

  registerExportHistory(data) {
    if (!!data) {
      const histories = JSON.parse(data);
      if (!!histories) {
        this.setState({ modelHistories: histories });
      }
    }
  }

  getFileNamesInFolder(folderId) {
    const endpoint = `documentVersion?filter=folderId eq '${folderId}'`;
    apiService
      .getDataByEndpoint(endpoint)
      .then((res) => {
        let names = res.data.map((d) =>
          d.name.split(".").slice(0, -1).join(".")
        );
        names = [...new Set(names)];
        const fileNames = names.map((n) => {
          return { value: n, label: n };
        });

        this.setState({ fileNames });
        if (!this.state.fileName) {
          const fileName = fileNames.length ? fileNames[0].value : undefined;
          this.setState({ fileName });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  initialize() {
    const disciplines = converters.getOptionsFromMetaData(
      this.props.disciplines
    );
    const buildings = converters.getOptionsFromMetaData(this.props.buildings);
    const floors = converters.getOptionsFromMetaData(this.props.floors);
    const discipline = disciplines.length ? disciplines[0].value : "";
    const building = buildings.length ? buildings[0].value : "";
    const floor = floors.length ? floors[0].value : "";
    this.setState({
      disciplines: disciplines,
      buildings: buildings,
      floors: floors,
      discipline: discipline,
      building: building,
      floor: floor,
    });

    if (window.mappingInfo) {
      const { isIfcMapping, mappingFilePath, mappingData } = window.mappingInfo;
      this.setState({ isIfcMapping, mappingFilePath, mappingData });
    }

    this.getFolderData();
  }

  async getFolderData() {
    await apiService
      .getFolderData()
      .then((res) => {
        if (res.data) {
          this.getTreeData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTreeData(folders) {
    const treeData = folders.map((f) => {
      return this.getFolderTree(f);
    });

    let { folder } = this.state;
    if (treeData.length) {
      if (!folder) {
        const data = treeData.find((f) => f.title === "Shared");
        if (data) folder = data.value;
      }
    }

    this.setState({ treeData, folder });
  }

  getFolderTree(folder) {
    let data = {};
    data.value = folder.id;
    data.title = folder.name;

    if (folder.childFolders) {
      data.children = folder.childFolders.map((sub) => {
        return this.getFolderTree(sub);
      });
    }

    return data;
  }

  requestFileName() {
    window.RequestFileName();
  }

  registerFileName(fileName) {
    if (fileName !== "Untitled") {
      this.setState({ fileName });
    }
  }

  openExportModal(e) {
    e.preventDefault();
    this.setState({
      isOpenModal: true,
      isSetName: true,
      isSetDiscipline: true,
      isSetBuilding: true,
      isSetFloor: true,
    });
  }

  closeExportModal(e) {
    e.preventDefault();
    this.setState({ isOpenModal: false });
  }

  async checkRoleAction() {
    return (
      getter.isActionAllowed(this.props.project.actions, roleAction.Document_Upload) &&
      getter.isActionAllowed(this.props.project.actions, roleAction.Document_IfcImport)
    );
  }

  async canExport() {
    const isActionAllowed = await this.checkRoleAction();
    if (!isActionAllowed) {
      const lang = window.isGerman ? de : en;
      window.AlertAPI(lang["CantExportIfc"]);
    }
    return isActionAllowed;
  }

  handleExportModel = async () => {
    const isActionAllowed = await this.canExport();
    if (!isActionAllowed) return;
    const fileName = this.nameRef.current ? this.nameRef.current.value : "";
    if (this.isFillText(fileName)) {
      this.setState({ exporting_ifc: true });
      window.ExportIFCModel(this.generateExportData());
    } else {
      const lang = window.isGerman ? de : en;
      window.AlertAPI(lang["FileNameMissing"]);
    }
  };

  onUploadCheck = async () => {
    const isActionAllowed = await this.canExport();
    if (!isActionAllowed) return;

    const fileName = this.nameRef.current?.value || "";
    if (this.isFillText(fileName)) {
      const endpoint = `document/check?folderId=${this.state.folder}`;
      apiService
        .postData(endpoint, [`${fileName}.ifc`])
        .then((res) => {
          const { precheckResults } = res.data;
          if (precheckResults.length) {
            const preCheckResult = precheckResults[0];
            if (preCheckResult.success) {
              const checkResult = {
                success: true,
                cleanFileName: preCheckResult.fileName,
                predictedVersion: preCheckResult.predictedVersion,
                namingSchemeName: preCheckResult.namingSchemeName,
              };
              this.setState({ checkResult, isExportEnable: true });
            } else {
              if (preCheckResult.namingSchemeResults.length) {
                var result = preCheckResult.namingSchemeResults.find(
                  (nsr) => nsr.success === false
                );
                if (!result.cleanFileName)
                  result.cleanFileName = preCheckResult.fileName;
                this.setState({
                  checkResult: result,
                  isExportEnable: result.success,
                });
              } else {
                this.displayErrorNameScheme();
              }
            }
          } else {
            this.displayErrorNameScheme();
          }
        })
        .catch((err) => {
          console.log("Don't check uploading document.", err.message);
          this.displayErrorNameScheme();
        });
    } else {
      const lang = window.isGerman ? de : en;
      window.AlertAPI(lang["FileNameMissing"]);
    }
  };

  displayErrorNameScheme() {
    const checkResult = {
      success: false,
      cleanFileName: this.nameRef.current?.value,
    };
    this.setState({ checkResult });
  }

  handleChangeDiscipline(discipline) {
    this.setState({ discipline: discipline });
  }

  handleChangeBuilding(building) {
    this.setState({ building: building });
  }

  handleChangeFloor(floor) {
    this.setState({ floor: floor });
  }

  changeUmlaut(origin) {
    return origin
      .replace(/\u00dc/g, "UE")
      .replace(/\u00fc/g, "ue")
      .replace(/\u00c4/g, "AE")
      .replace(/\u00e4/g, "ae")
      .replace(/\u00d6/g, "OE")
      .replace(/\u00f6/g, "oe")
      .replace(/\u00df/g, "ss");
  }

  handleClickCheck(e) {
    this.setState({ isChecked: e.target.checked });
  }

  onChangeOnlyVisibleElement = (e) => {
    this.setState({ isOnlyVisibleElement: e.target.checked });
  };

  generateExportData() {
    let exportData = {};

    exportData.folderId = this.state.folder;
    exportData.projectId = this.props.project.id;
    exportData.fileName = this.nameRef.current.value;
    exportData.token = getter.getTokenBearer();
    exportData.onlyVisible = this.state.isOnlyVisibleElement;
    exportData.useMapFile = this.state.isIfcMapping;

    return JSON.stringify(exportData);
  }

  isFillText(text) {
    if (text) {
      const trimText = text.trim();
      return trimText !== "";
    }

    return false;
  }

  exportModel(docString) {
    if (docString) {
      const documents = JSON.parse(docString);
      if (documents) {
        if (this.state.isAutoImport) {
          const id = documents[0].id;
          const persistDto = {};
          persistDto.id = id;
          persistDto.disciplineMetaDataIds = { value: [this.state.discipline] };
          persistDto.buildingMetaDataId = { value: this.state.building };
          persistDto.floorMetaDataId = { value: this.state.floor };

          apiService
            .putData("documentVersion", [persistDto])
            .then((response) => {
              apiService.patchIfcDocument(id).then((res) => {
                this.finishExportModel(res.data, response.data);
              });
            })
            .catch(() => {
              this.finishExportModel(false);
            });
        } else {
          this.finishExportModel(true, documents);
        }

        return;
      }
    }

    this.finishExportModel(false);
  }

  finishExportModel(result, documentVersion) {
    const lang = window.isGerman ? de : en;
    if (!!result) {
      if (!!documentVersion) {
        const modelHistories = [...this.state.modelHistories];
        const doc = documentVersion[0];
        modelHistories.push({
          title: doc.name,
          version: doc.versionNumber,
          uploadDate: doc.uploadDate,
        });

        this.setState({ modelHistories });
      }
      window.AlertAPI(lang["SucceedExportIFC"]);
    } else {
      window.AlertAPI(lang["FaileExportIFC"]);
    }

    this.setState({ exporting_ifc: false });
  }

  createFormData(ifc) {
    const blob = new Blob([ifc], { type: "text/plain" });
    let formData = new FormData();
    formData.append("file", blob, `${this.state.ifcFile}.ifc`);

    return formData;
  }

  createFileMetaData(uploadHandle) {
    const { discipline, building, floor, isChecked } = this.state;
    return {
      categoryMetaDataId: discipline,
      buildingMetaDataId: building,
      floorMetaDataId: floor,
      uploadHandle: uploadHandle,
      storeAsDocument: isChecked,
    };
  }

  createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  onChangeIfcMapping = (e) => {
    this.setState({ isIfcMapping: e.target.checked });
  };

  handleOpenFile = () => {
    window.openMappingFile();
  };

  setMappingFilePath(mappingFilePath) {
    this.setState({ mappingFilePath });
  }

  readMappingFile(file) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = (e) => {
      const readerData = e.target.result;
      const options = {
        ignoreAttributes: false,
      };
      const xml = new XMLParser(options).parse(readerData);

      if (xml.SchemeItemList) {
        this.setState({
          mappingFilePath: file.name,
          mappingData: xml.SchemeItemList,
        });
      } else {
        const lang = window.isGerman ? de : en;
        window.AlertAPI(lang["NotMappingFile"]);
      }
    };
  }

  onFolderChange = (folder) => {
    this.setState({ folder, isExportEnable: false, checkResult: undefined });
  };

  onNameChange = () => {
    this.setState({ isExportEnable: false, checkResult: undefined });
  };

  renderTableData() {
    return this.state.modelHistories.map((model, index) => {
      const { title, version, uploadDate } = model;
      return (
        <tr
          className="striped_row"
          key={index}
          onClick={this.handleSelectModel}
        >
          <td width="50%">{title}</td>
          <td width="20%">{`V.${version}`}</td>
          <td width="30%">{Moment(uploadDate).format("DD.MM.YYYY")}</td>
        </tr>
      );
    });
  }

  getSchemeResult = (nameScheme) => {
    const lang = window.isGerman ? de : en;
    if (nameScheme.success) {
      const content = lang["NameValidSuccess"]
        .replace("{{versionNumber}}", nameScheme.predictedVersion)
        .replace("{{fileName}}", nameScheme.cleanFileName);
      return nameScheme.namingSchemeName
        ? `${lang[nameScheme.namingSchemeName]}: ${content}`
        : content;
    } else {
      if (nameScheme.errorType === "NamingSchemaValidationFailed") {
        return lang["NamingSchemaValidationFailed"]
          .replace("{{group}}", nameScheme.failedGroupName)
          .replace("{{namingSchema}}", nameScheme.namingSchemeName);
      }

      return nameScheme.namingSchemeName && nameScheme.errorType
        ? `${lang[nameScheme.namingSchemeName]}: ${lang[nameScheme.errorType]}}`
        : lang["NoNamingScheme"];
    }
  };

  onSetAutoImport = () => {
    const isAutoImport = !this.state.isAutoImport;
    this.setState({
      isAutoImport,
    });
  };

  renderDocName() {
    const nameScheme = this.state.checkResult;
    const firstName = nameScheme.success
      ? nameScheme.cleanFileName
      : nameScheme.cleanFileName.slice(0, nameScheme.errorPosition);
    const middleName = nameScheme.success
      ? ""
      : nameScheme.cleanFileName.slice(
          nameScheme.errorPosition,
          nameScheme.errorPosition + nameScheme.errorLength
        );
    const lastName = nameScheme.success
      ? ""
      : nameScheme.cleanFileName.slice(
          nameScheme.errorPosition + nameScheme.errorLength,
          nameScheme.cleanFileName.length
        );
    return (
      <>
        <span>{firstName}</span>
        <span style={{ color: "red" }}>
          {nameScheme.errorPosition !== undefined ? middleName : ""}
        </span>
        <span>{nameScheme.errorPosition !== undefined ? lastName : ""}</span>
      </>
    );
  }

  render() {
    const lang = window.isGerman ? de : en;
    const {
      fileNames,
      fileName,
      disciplines,
      discipline,
      buildings,
      building,
      checkResult,
      floors,
      floor,
      exporting_ifc,
      treeData,
      folder,
      modelHistories,
      isIfcMapping,
      mappingFilePath,
      isExportEnable,
      isAutoImport,
    } = this.state;

    return (
      <>
        <div>
          <div className="setting-panel">
            <h3>{lang["General"]}</h3>
            <label>{lang["FileName"]}</label>
            <CreateNameSelect
              options={fileNames}
              defaultValue={fileName}
              onChange={this.onNameChange}
              ref={this.nameRef}
            />
            <label>{lang["ExportFolder"]}</label>
            {treeData && (
              <TreeSelect
                suffixIcon={<CaretDownFilled />}
                style={{ width: "100%" }}
                value={folder}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                onChange={this.onFolderChange}
                treeData={treeData}
              />
            )}
            <div className="exportModel_settingFile">
              <input
                id="property-mapping"
                type={"checkbox"}
                checked={isIfcMapping}
                onChange={this.onChangeIfcMapping}
              />
              <div htmlFor="property-mapping" className="exportModel_FilePath">
                <input
                  type={"text"}
                  style={{ minWidth: "250px" }}
                  disabled={!isIfcMapping}
                  readOnly={true}
                  value={mappingFilePath}
                />
                <button
                  className={
                    isIfcMapping ? "visoplan_button" : "visoplan_button_disable"
                  }
                  style={{ width: "32px", margin: "0 5px" }}
                  disabled={!isIfcMapping}
                  onClick={this.handleOpenFile}
                >
                  <FontAwesomeIcon icon={faFolder} />
                </button>
              </div>
            </div>
          </div>
          <div className="setting-panel">
            {/* <h3>{lang["DefineModel"]}</h3> */}
            <div className="horizental_align">
              <input
                id="import_model"
                type="checkbox"
                checked={isAutoImport}
                onChange={this.onSetAutoImport}
              />
              <div
                htmlFor="import_model"
                onClick={this.onSetAutoImport}
                style={{
                  margin: "0 5px",
                }}
              >
                {lang["ImportModelAutomatically"]}
              </div>
            </div>
            <div className="metadata-panel">
              <div
                className={`metadata-item ${isAutoImport ? "" : "disabled"}`}
              >
                <label>{lang["Discipline"]}</label>
                {discipline && (
                  <VisoSelect
                    options={disciplines}
                    defaultValue={discipline}
                    onChange={this.handleChangeDiscipline}
                  />
                )}
              </div>
              <div
                className={`metadata-item ${isAutoImport ? "" : "disabled"}`}
              >
                <label>{lang["Building"]}</label>
                {building && (
                  <VisoSelect
                    options={buildings}
                    defaultValue={building}
                    onChange={this.handleChangeBuilding}
                  />
                )}
              </div>
            </div>
            <div className="metadata-panel">
              <div
                className={`metadata-item ${isAutoImport ? "" : "disabled"}`}
              >
                <label>{lang["Floor"]}</label>
                {floor && (
                  <VisoSelect
                    options={floors}
                    defaultValue={floor}
                    onChange={this.handleChangeFloor}
                  />
                )}
              </div>
            </div>
            <div className="metadata-panel">
              <button
                className="visoplan_button"
                style={{ margin: "10px 0", width: "48%" }}
                onClick={this.onUploadCheck}
              >
                {lang["UploadCheck"]}
              </button>
            </div>
            <div>
              <label style={{ fontWeight: "bold" }}>
                {lang["UploadCheck"]}
              </label>
              <div className="metadata-panel">
                <label style={{ width: "35%" }}>{lang["Name"]}</label>
                <label style={{ width: "65%" }}>{"Information"}</label>
              </div>
              {checkResult && (
                <div
                  className="metadata-panel"
                  style={{ width: "calc(100vw - 40px)" }}
                >
                  <label
                    style={{
                      width: "35%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {this.renderDocName()}
                  </label>
                  <label
                    style={{
                      width: "65%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      color: `${checkResult.success ? "none" : "red"}`,
                    }}
                  >
                    {this.getSchemeResult(checkResult)}
                  </label>
                </div>
              )}
            </div>
            {isExportEnable && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="visoplan_Actbutton"
                  onClick={this.handleExportModel}
                  style={{ margin: "10px 0 0 0", width: "120px" }}
                >
                  {lang["ExportModel"]}
                </button>
              </div>
            )}
          </div>
          <div className="exportModel_table scrollbar">
            <label style={{ margin: "5px", fontWeight: "bold" }}>
              {lang["ExportHistory"]}
            </label>
            <table>
              <thead>
                <tr className="no_border">
                  <th>{lang["Name"]}</th>
                  <th>{"Version"}</th>
                  <th>{"Date"}</th>
                </tr>
              </thead>
              <tbody>{modelHistories && this.renderTableData()}</tbody>
            </table>
          </div>
        </div>
        <Backdrop
          style={{
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
          open={exporting_ifc}
        >
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
    user: state.user.currentUser,
    models: state.models.models,
    disciplines: state.metaData.disciplines,
    buildings: state.metaData.buildings,
    floors: state.metaData.floors,
  };
};

export default connect(mapStateToProps, null)(ExportModel);
