import React from "react";
import { connect } from "react-redux";
import { Input } from "antd";
import VisoSelect from "./controls/viso_select";
import IssueItem from "./controls/issueItem";
import { issuesActions } from "../redux/actions";
import { issueOwner, pageState, roleAction } from "./enums";
import { converters } from "./helper";
import { apiService } from "../services";
import { debounce } from "lodash";
import en from "../assets/en.json";
import de from "../assets/de.json";
import filterImg from "../assets/Filter.png";
import "../css/issueList.css";

const options = converters.getOptionsFromEnum(issueOwner);
const { Search } = Input;

class IssueList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { creatable: false, keyword: "", top: 10, skip: 0 };
    this.handleChangeOwner = this.handleChangeOwner.bind(this);
    this.handleOpenFilter = this.handleOpenFilter.bind(this);
    this.hanldleNewIssue = this.hanldleNewIssue.bind(this);
    this.handleOpenIssue = this.handleOpenIssue.bind(this);

    this.isLoading = false;
    this.wheelCount = 0;
  }

  componentDidMount() {
    if (this.props.project.id) {
      this.getIssues(this.state.skip, this.state.keyword);
      this.props.clearSelectedIssue();
      this.setCreatable();
    }

    this.timerId = setInterval(() => {
      this.syncIssues();
    }, 60000);

    this.setScrollEvent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.owner !== this.props.owner) {
      this.getIssues(0, this.state.keyword, true);
    }

    if (prevProps.filterData !== this.props.filterData) {
      this.getIssues(0, this.state.keyword, true);
    }
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  syncIssues() {
    if (!this.isLoading) {
      this.getIssues(this.state.skip, this.state.keyword);
    }
  }

  setCreatable() {
    const actions = this.props.project.actions;
    if (actions) {
      const action =
        actions !== undefined &&
        actions.find(
          (a) => a.action === roleAction.IssueManagement_Creation
        );
      this.setState({ creatable: !action.isAllowed });
    } else {
      this.setState({ creatable: true });
    }
  }

  getIssues = async (skip, keyword, initial = false) => {
    this.wheelCount = 0;

    const { filterData } = this.props;
    let query = [];
    if (filterData) {
      const keys = Object.keys(filterData).filter(
        (k) => filterData[k] && filterData[k] !== "All"
      );
      if (keys) {
        query = keys.map((key) => {
          let value = undefined;
          switch (key) {
            case "creationDateAfter":
              value = `creationDate ge ${new Date(
                filterData[key]
              ).toISOString()}`;
              break;
            case "creationDateBefore":
              value = `creationDate le ${new Date(
                filterData[key]
              ).toISOString()}`;
              break;
            case "dueDateAfter":
              value = `dueDate ge ${new Date(filterData[key]).toISOString()}`;
              break;
            case "dueDateBefore":
              value = `dueDate le ${new Date(filterData[key]).toISOString()}`;
              break;
            case "startDateAfter":
              value = `startingDate ge ${new Date(
                filterData[key]
              ).toISOString()}`;
              break;
            case "startDateBefore":
              value = `startingDate le ${new Date(
                filterData[key]
              ).toISOString()}`;
              break;
            case "editDateAfter":
              value = `editDate ge ${new Date(filterData[key]).toISOString()}`;
              break;
            case "editDateBefore":
              value = `editDate le ${new Date(filterData[key]).toISOString()}`;
              break;
            case "statusIds":
              if (filterData[key].length) {
                value = `${filterData[key]
                  .map((sId) => {
                    return `(statusId eq '${sId}')`;
                  })
                  .join(" or ")}`;
              }
              break;
            case "priorityId":
              value = `priorityId eq '${filterData[key]}'`;
              break;
            case "source":
              value = `source eq '${filterData[key]}'`;
              break;
            case "typeId":
              value = `typeId eq '${filterData[key]}'`;
              break;
            case "buildingMetadataIds":
              if (filterData[key].length)
                value = `buildingMetaDataIds/any(buildingMetaDataIds: buildingMetaDataIds eq '${filterData[key][0]}')`;
              break;
            case "floorMetaDataIds":
              if (filterData[key].length)
                value = `floorMetaDataIds/any(floorMetaDataIds: floorMetaDataIds eq '${filterData[key][0]}')`;
              break;
            case "disciplineMetaDataIds":
              if (filterData[key].length)
                value = `disciplineMetaDataIds/any(disciplineMetaDataIds: disciplineMetaDataIds eq '${filterData[key][0]}')`;
              break;
            case "workPhaseId":
              value = `workPhaseId eq '${filterData[key]}'`;
              break;
            case "tagIds":
              if (filterData[key].length)
                value = `tagIds/any(tagIds: tagIds eq '${filterData[key][0]}')`;
              break;
            case "assignedCollaboratorIds":
              if (filterData[key].length) {
                value = `(assignedCollaboratorIds/any(assignedCollaboratorIds: assignedCollaboratorIds eq '${filterData[key][0]}'))`;
              }
              break;
            case "createAuthorId":
              value = `createAuthorId eq '${filterData[key]}'`;
              break;
            case "reviewerId":
              value = `reviewerId eq '${filterData[key]}'`;
              break;
            case "editAuthorId":
              value = `editAuthorId eq '${filterData[key]}'`;
              break;
            default:
              break;
          }

          return value ? `(${value})` : undefined;
        });

        query = query.filter((q) => q);
      }
    }

    const { top } = this.state;
    // if (owner !== issueOwner.ALL.value) {
    //   query.push(
    //     `(assignedCollaboratorIds/any(assignedCollaboratorIds: assignedCollaboratorIds eq '${this.props.user.id}'))`
    //   );
    // }

    if (keyword !== "") {
      query.push(
        `((contains(tolower(title),'${keyword.toLowerCase()}')) or (contains(tolower(description),'${keyword.toLowerCase()}')) or (issueNumber eq ${parseInt(
          keyword
        )}))`
      );
    }

    const endPoint = query.length
      ? `issue?&filter=(${query.join(
          " and "
        )})&orderby=editDate desc&top=${top}&skip=${skip}`
      : `issue?&orderby=editDate desc&top=${top}&skip=${skip}`;

    await apiService
      .getDataByEndpoint(endPoint)
      .then((res) => {
        if (skip === 0 || res.data.length) {
          this.props.registerIssues(res.data);
          setScrollPosition(skip, this.state.skip);
          this.setState({ skip });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });

    function setScrollPosition(newSkip, origin) {
      const listBody = document.getElementById("issue-list");
      if (newSkip > origin || initial) {
        listBody.scrollTop = 5;
      } else if (newSkip < origin) {
        listBody.scrollTop = listBody.scrollHeight - listBody.clientHeight;
      }
    }
  };

  handleChangeOwner(selectedOption) {
    this.props.changeOwner(selectedOption);
  }

  handleSearch = (e) => {
    const keyword = e.target.value;
    this.setState({ keyword });
    this.getIssues(0, keyword, true);
  };

  handleOpenFilter(e) {
    e.preventDefault();
    this.props.onSetPage(pageState.FILTERISSUE);
  }

  hanldleNewIssue(e) {
    e.preventDefault();
    this.props.onSetPage(pageState.EDITISSUE);
  }

  handleOpenIssue() {
    this.props.onSetPage(pageState.DETAILISSUE);
  }

  setScrollEvent = () => {
    const issueListBody = document.getElementById("issue-list");
    issueListBody.addEventListener(
      "scroll",
      debounce((event) => {
        this.listenScrollEvent(event);
      }, 500)
    );
  };

  //#TODO Should change workflow as web client
  listenScrollEvent = (event) => {
    if (!this.isLoading) {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      const { top, skip, keyword } = this.state;

      if (scrollHeight - clientHeight - scrollTop < 10 && scrollTop > 0) {
        this.wheelCount++;
      } else if (scrollTop === 0 && skip >= top) {
        this.isLoading = true;
        this.getIssues(skip - top, keyword);
      } else {
        this.wheelCount = 0;
      }

      if (this.wheelCount > 3) {
        this.isLoading = true;
        this.getIssues(top + skip, keyword);
      }
    }
  };

  renderIssues() {
    const history = this.props.history;
    return this.props.issues.map((issue, index) => {
      return (
        <IssueItem
          issue={issue}
          history={history}
          key={index}
          onDblClick={this.handleOpenIssue}
        />
      );
    });
  }

  render() {
    const lang = window.isGerman ? de : en;
    const { owner, project, issues } = this.props;
    const { creatable } = this.state;

    return (
      <div className="issueList_container">
        <div className="issueList_header">
          <h2>{lang["Issues"]}</h2>
          <div className="rightSide">
            <label>{project.name}</label>
          </div>
        </div>
        <div className="issueList_blank">
          <Search onChange={this.handleSearch} allowClear={true} />
        </div>
        <div className="issueList_buttonBar">
          <div style={{ width: "100px" }}>
            {owner && (
              <VisoSelect
                options={options}
                onChange={this.handleChangeOwner}
                defaultValue={owner}
              />
            )}
          </div>
          <div className="rightSide">
            <button
              className="visoplan_button"
              onClick={this.handleOpenFilter}
              style={{ display: "inline-block", marginRight: "5px" }}
            >
              <img
                src={filterImg}
                alt=""
                style={{ float: "left", paddingLeft: "1em" }}
              />
              {lang["Filter"]}
            </button>
            <button
              className={
                creatable ? "visoplan_button_disable" : "visoplan_button"
              }
              style={{ width: "120px" }}
              disabled={creatable}
              onClick={this.hanldleNewIssue}
            >
              {lang["NewIssue"]}
            </button>
          </div>
        </div>
        <div
          id="issue-list"
          className="issueList_body scrollbar"
          onScroll={this.listenScrollEvent}
        >
          {issues && this.renderIssues()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
    issues: state.issues.issues,
    opened: state.issues.opened,
    owner: state.issues.owner,
    filterData: state.filter.filterData,
    user: state.user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIssues: (ids) => {
      dispatch(issuesActions.getIssues(ids));
    },
    changeOwner: (owner) => {
      dispatch(issuesActions.setOwner(owner));
    },
    clearSelectedIssue: () => {
      dispatch(issuesActions.clearSelectedIssue());
    },
    registerIssues: (issues) => {
      dispatch(issuesActions.registerIssues(issues));
    },
    resetAddedIssueFlag: () => {
      dispatch(issuesActions.resetAddedIssueFlag());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueList);
