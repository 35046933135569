import React from "react";
import { connect } from "react-redux";
import { converters, getter } from "./helper";
import { editResult, issueVisibility, pageState } from "./enums";
import { editIssueActions, viewpointActions } from "../redux/actions";
import { Select, Divider } from "antd";
import VisoSelect from "./controls/viso_select";
import VisoDatePicker from "./controls/viso_datepicker";
import { CaretDownFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "./controls/image";
import en from "../assets/en.json";
import de from "../assets/de.json";
import "../css/issueEdit.css";

const { Option } = Select;

class IssueEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: true,
      title: "",
      users: [],
      assign: [],
      reviewer: "",
      assigners: [],
      disciplines: [],
      discipline: [],
      buildings: [],
      building: "",
      floors: [],
      floor: "",
      priority: "",
      type: "",
      dueDate: Date.now(),
      description: "",
      elements: [],
      viewpoints: [],
      addComponent: false,
      deletingViewpoints: [],
      isEmptyTitle: false,
      isEmptyDesc: false,
      isEmptyAssigners: false,
      tempViewpoint: {},
      startDate: Date.now(),
      workStages: [],
      workPhase: "",
      tags: [],
      issueTags: [],
      status: [],
      statuses: [],
      visibilities: [],
      visibility: undefined,
      roleDefinitions: [],
      priorities: [],
      types: [],
    };
    this.handleCancle = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeAssigner = this.handleChangeAssigner.bind(this);
    this.handleChangeReviewer = this.handleChangeReviewer.bind(this);
    this.handleChangeDiscipline = this.handleChangeDiscipline.bind(this);
    this.handleChangePriority = this.handleChangePriority.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.handleAddComponent = this.handleAddComponent.bind(this);
    this.handleAddViewpoint = this.handleAddViewpoint.bind(this);
    this.handleDeleteViewpoint = this.handleDeleteViewpoint.bind(this);
    this.handleChangeBuilding = this.handleChangeBuilding.bind(this);
    this.handleChangeFloor = this.handleChangeFloor.bind(this);
    window.IssueEdit = this;
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    this.invokeAddComponent(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.snapshotFlag === true && prevProps.snapshotFlag === false) {
      this.insertViewpoints();
    }

    if (this.props.editResult !== prevProps.editResult) {
      this.setPageState(this.props.editResult);
    }
  }

  initialize() {
    const priorities = this.getPriorityOptions();
    const types = this.getTypeOptions();
    const lang = window.isGerman ? de : en;
    const issue = this.props.selectedIssue;
    const isNew = issue === undefined;
    const title = isNew ? `${lang["NewIssue"]}` : issue.title;
    const users = this.getUserOptions();
    const assigners = isNew ? [] : issue.assignedCollaboratorIds;
    const reviewer = issue?.reviewerId;
    const disciplines = converters.getOptionsFromMetaData(
      this.props.disciplines
    );
    const discipline = isNew ? [] : issue.disciplines;
    const buildings = converters.getOptionsFromMetaData(this.props.buildings);
    const building = isNew
      ? undefined
      : issue.buildings.length
      ? issue.buildings[0]
      : undefined;
    const floors = converters.getOptionsFromMetaData(this.props.floors);
    const floor = isNew
      ? undefined
      : issue.floors.length
      ? issue.floors[0]
      : undefined;
    const priority = isNew
      ? this.props.priorities.find((p) => p.originalName === "Normal")?.id
      : issue.issuePriority?.id;
    const type = isNew
      ? this.props.types.find((t) => t.originalName === "Issue")?.id
      : issue.issueType?.id;
    const description = isNew ? "" : issue.description;
    const dueDate = isNew ? "" : issue.dueDate;
    const startDate = isNew ? "" : issue.startingDate;
    const workStages = converters.getOptionsFromWorkphase(
      this.props.project.workPhases
    );
    const workPhase = isNew ? undefined : issue.workPhaseId;
    const tags = converters.getOptionsFromTag(this.props.project.tags);
    const issueTags = isNew ? [] : issue.tags;
    const statuses = converters.getOptionsFromStatus(this.props.statuses);
    const status = isNew ? this.props.statuses.find((t) => t.originalName === "Open")?.id : issue.issueStatus;

    let visibilities = converters.getOptionsFromEnum(issueVisibility);
    visibilities.splice(2, 1); // Remove restricted

    if (!isNew) {
      if (issue.visibility !== issueVisibility.PRIVATE.value) {
        const index = visibilities
          .map((v) => v.value)
          .indexOf(issueVisibility.PRIVATE.value);
        if (index > -1) {
          visibilities.splice(index, 1);
        }
      }
    }

    let roleDefinitions = this.props.project.userGroups
      .map((r) => {
        return {
          value: r.id,
          label: r.name,
          checked: isNew
            ? false
            : issue.visibility === issueVisibility.RESTRICTED.value
            ? issue.allowedUserGroups.includes(r.id)
            : false,
        };
      });

    const visibility = isNew
      ? issueVisibility.PUBLIC.value
      : issue.visibility === issueVisibility.RESTRICTED.value
      ? issueVisibility.RESTRICTED.label
      : issue.visibility;

    this.setState({
      isNew,
      title,
      users,
      assigners,
      reviewer,
      startDate,
      disciplines,
      discipline,
      buildings,
      building,
      floors,
      floor,
      priorities,
      types,
      priority,
      type,
      description,
      dueDate,
      workStages,
      workPhase,
      tags,
      issueTags,
      statuses,
      status,
      visibilities,
      roleDefinitions,
      visibility,
    });

    if (!isNew) {
      this.getViewpoints();
      this.requestElementNames();
    }
  }

  getPriorityOptions() {
    return this.props.priorities.map((s) => {
      return { value: s.id, label: s.name };
    });
  }

  getTypeOptions() {
    return this.props.types.map((s) => {
      return { value: s.id, label: s.name };
    });
  }

  setPageState(result) {
    if (this.state.isNew) {
      if (result === editResult.SUCCESS) {
        this.props.onSetPage(pageState.DETAILISSUE);
      } else {
        this.props.onSetPage(pageState.ISSUELIST);
      }
    } else {
      this.props.onSetPage(pageState.DETAILISSUE);
    }

    this.props.resetPageState();

    const lang = window.isGerman ? de : en;
    let message = "";
    if (this.state.isNew) {
      message =
        result === editResult.SUCCESS
          ? lang["CreateSucceed"]
          : lang["CreateFailed"];
    } else {
      message =
        result === editResult.SUCCESS
          ? lang["UpdateSucceed"]
          : lang["UpdateFailed"];
    }

    window.AlertAPI(message);
  }

  requestElementNames() {
    let ids = this.props.selectedIssue.linkedComponentsGlobalIds;
    ids = [...new Set(ids)];
    if (ids.length > 0) {
      window.GetElementNames(ids, false);
    }
  }

  registerElementNames(elemInfos) {
    const elements = elemInfos.map((e) => {
      const jsObject = JSON.parse(e);
      return jsObject;
    });
    this.setState({ elements: elements });
  }

  getViewpoints() {
    let vps = this.props.viewpoints
      .filter((v) => v.issueId === this.props.selectedIssue.id)
      .filter((v) => v.deleted === false);
    this.setState({ viewpoints: vps });
  }

  getUserOptions() {
    return this.props.project.userPreviews.map((u) => {
      return { value: u.id, label: `${u.firstName} ${u.lastName}` };
    });
  }

  handleChangeAssigner(assigners) {
    this.setState({ assigners: assigners });
  }

  handleChangeTags = (issueTags) => {
    this.setState({ issueTags });
  };

  handleChangeStatus = (status) => {
    this.setState({ status });
  };

  handleChangeReviewer(reviewer) {
    this.setState({ reviewer: reviewer });
  }

  handleChangeDiscipline(discipline) {
    this.setState({ discipline });
  }

  handleChangeBuilding(building) {
    this.setState({ building });
  }

  handleChangeFloor(floor) {
    this.setState({ floor });
  }

  handleChangePriority(priority) {
    this.setState({ priority });
  }

  handleChangeType(type) {
    this.setState({ type });
  }

  handleChangeDueDate(date) {
    this.setState({ dueDate: date });
  }

  handleChangeStartDate = (date) => {
    this.setState({ startDate: date });
  };

  handleChangeWorkStage = (workPhase) => {
    this.setState({ workPhase });
  };

  handleChangeDesc(e) {
    e.preventDefault();
    this.setState({ description: e.target.value.trim() });
  }

  handleChangeTitle(e) {
    e.preventDefault();
    this.setState({ title: e.target.value.trim() });
  }

  handleCancel(e) {
    e.preventDefault();
    if (this.state.isNew) {
      this.props.onSetPage(pageState.ISSUELIST);
    } else {
      this.props.onSetPage(pageState.DETAILISSUE);
    }
  }

  handleDeleteViewpoint(imageId) {
    let { viewpoints, deletingViewpoints } = this.state;
    const vpIndex = viewpoints.findIndex((v) => v.imageId === imageId);
    if (vpIndex > -1) {
      const viewpoint = viewpoints[vpIndex];
      if (viewpoint.id) {
        deletingViewpoints = [...deletingViewpoints, viewpoint];
        this.setState({ deletingViewpoints: deletingViewpoints });
      }

      viewpoints.splice(vpIndex, 1);
      this.setState({ viewpoints: viewpoints });
    }
  }

  handleAddComponent(e) {
    e.preventDefault();
    const adding = !this.state.addComponent;
    this.invokeAddComponent(adding);
  }

  invokeAddComponent(flag) {
    window.AddComponent(flag);
    this.setState({ addComponent: flag });
  }

  AddComponents(components) {
    const elements = components.map((c) => {
      return JSON.parse(c);
    });

    this.setState({ elements: elements });
  }

  handleAddViewpoint(e) {
    e.preventDefault();
    const data = {
      token: getter.getTokenBearer(),
      projectId: this.props.project.id,
    };
    window.AddViewpoint(JSON.stringify(data));
  }

  AddViewpoint(vpString) {
    if (vpString) {
      try {
        const viewpoint = JSON.parse(vpString);
        this.setState({ tempViewpoint: viewpoint });
        if (viewpoint.imageId !== "") {
          this.props.postImage(viewpoint.imageId);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  insertViewpoints() {
    let viewpoint = this.state.tempViewpoint;
    if (viewpoint && viewpoint.imageId) {
      viewpoint.snapshotId = this.props.snapshotId;
      let viewpoints = this.state.viewpoints;
      viewpoints = [...viewpoints, viewpoint];
      this.props.clearSnapshot();
      this.setState({ viewpoints: viewpoints, tempViewpoint: {} });
    }
  }

  handleSave(e) {
    e.preventDefault();

    if (this.checkSavable()) return;

    const persistIssueDto = this.getPeristIssueDto();
    const persistViewpointDtos = this.getPersistViewpointDtos();
    if (this.state.isNew) {
      this.props.createIssue([persistIssueDto], persistViewpointDtos);
    } else {
      const deletedIds = this.state.deletingViewpoints.map((v) => {
        return v.id;
      });

      this.props.updateIssue(
        [persistIssueDto],
        deletedIds,
        persistViewpointDtos
      );
    }
  }

  getPersistViewpointDtos() {
    return this.state.viewpoints
      .filter((v) => !v.id || v.id === "")
      .map((newVp) => {
        return this.generatePersistViewpoint(newVp);
      });
  }

  generatePersistViewpoint(newVp) {
    let persistViewpointDto = {};
    if (!this.state.isNew) {
      persistViewpointDto.IssueId = { value: this.props.selectedIssue.id };
    }

    persistViewpointDto.ImageId = { value: newVp.snapshotId };
    persistViewpointDto.ImageName = { value: `${newVp.snapshotId}.png` };
    persistViewpointDto.Index = { value: 0 };
    persistViewpointDto.CreatingTool = { value: newVp.creatingTool };
    persistViewpointDto.CameraType = { value: newVp.cameraType };
    persistViewpointDto.Location = { value: newVp.location };
    persistViewpointDto.Direction = { value: newVp.direction };
    persistViewpointDto.Up = { value: newVp.up };
    persistViewpointDto.CameraFactor = { value: newVp.cameraFactor };

    // visibility
    const visibility = newVp.visibility === 1 ? true : false;
    persistViewpointDto.Visibility = { value: visibility };
    // visual states
    let visualStates = {};
    visualStates.SelectedGlobalIds = newVp.selectedGlobalIds.map((i) => {
      return i.ifcGuid;
    });
    visualStates.ColorGroups = []; // Can't set and get a color of element in Archicad.
    visualStates.TransparentGlobalIds = [];
    visualStates.VisbilityExceptionGlobalIds =
      newVp.visbilityExceptionGlobalIds.map((i) => {
        return i.ifcGuid;
      });

    persistViewpointDto.VisualStates = { value: visualStates };

    // Sections
    if (newVp.sections)
      persistViewpointDto.sections = { value: newVp.sections };

    return persistViewpointDto;
  }

  getPeristIssueDto() {
    const issue = this.props.selectedIssue;
    const isNew = this.state.isNew;

    let persistIssueDto = {};
    if (!isNew) {
      persistIssueDto.Id = issue.id;
      if (!issue.thumbnailImageId) {
        persistIssueDto.thumbnailImageId = { value: null };
      }
    } else {
      persistIssueDto.ProjectId = { value: this.props.project.id };
      persistIssueDto.Source = { value: "Archicad" };

      const st = this.props.statuses.find((s) => s.originalName === "Open");
      if (st) {
        persistIssueDto.StatusId = { value: st.id };
      }

      persistIssueDto.thumbnailImageId = { value: null };
    }
    persistIssueDto.Title = { value: this.state.title };
    persistIssueDto.Description = { value: this.state.description };
    const dueDate = !!this.state.dueDate ? this.state.dueDate : null;
    persistIssueDto.DueDate = { value: dueDate };
    // assinged user ids
    persistIssueDto.AssignedCollaboratorIds = { value: this.state.assigners };

    // reviewer id
    const reviewer = !!this.state.reviewer ? this.state.reviewer : null;
    persistIssueDto.ReviewerId = { value: reviewer };

    // type
    if (this.state.type) persistIssueDto.TypeId = { value: this.state.type };

    // priority
    if (this.state.priority)
      persistIssueDto.PriorityId = { value: this.state.priority };

    // linked component ids
    const componentIds = this.state.elements.map((e) => {
      return e.ifcGuid;
    });
    persistIssueDto.LinkedComponentsGlobalIds = { value: componentIds };

    // disciplines
    persistIssueDto.disciplines = { value: this.state.discipline };

    // Buildings
    if (this.state.building) {
      let buildings = [this.state.building];
      if (!isNew) {
        buildings = [...buildings, ...issue.buildings];
        buildings = [...new Set(buildings)];
      }
      persistIssueDto.buildings = { value: buildings };
    }

    // Floors
    if (this.state.floor) {
      let floors = [this.state.floor];
      if (!isNew) {
        floors = [...floors, ...issue.floors];
        floors = [...new Set(floors)];
      }
      persistIssueDto.floors = { value: floors };
    }

    // StartDate
    const startDate = !!this.state.startDate ? this.state.startDate : null;
    persistIssueDto.StartingDate = { value: startDate };

    // Workphase
    if (this.state.workPhase)
      persistIssueDto.workPhaseId = { value: this.state.workPhase };

    // TagIds
    persistIssueDto.tagIds = { value: this.state.issueTags };

    // Status
    persistIssueDto.Status = { value: this.state.status };

    // Visibility
    if (this.state.visibility !== undefined) {
      if (this.state.visibility === issueVisibility.RESTRICTED.label) {
        persistIssueDto.visibility = {
          value: issueVisibility.RESTRICTED.value,
        };
        persistIssueDto.allowedUserGroups = {
          value: this.state.roleDefinitions
            .filter((r) => r.checked)
            .map((r) => r.value),
        };
      } else {
        persistIssueDto.visibility = { value: this.state.visibility };
      }
    }

    return persistIssueDto;
  }

  checkSavable() {
    const { title, description, startDate, dueDate } = this.state;
    const isEmptyTitle = !title || title === "";
    const isEmptyDesc = !description || description === "";
    // const isEmptyAssigners = assigners.length === 0;
    this.setState({ isEmptyTitle, isEmptyDesc });

    if (!!startDate && !!dueDate) {
      const start = Date.parse(startDate);
      const due = Date.parse(dueDate);
      if (start > due) {
        const lang = window.isGerman ? de : en;
        window.AlertAPI(lang["StartDateCheck"]);

        return true;
      }
    }

    return isEmptyTitle | isEmptyDesc;
  }

  checkBoxChanged = (id) => {
    let { roleDefinitions, visibility } = this.state;

    roleDefinitions = roleDefinitions.map((r) => {
      if (r.value === id) {
        return { ...r, checked: !r.checked };
      }

      return r;
    });

    const lang = window.isGerman ? de : en;
    visibility =
      roleDefinitions.findIndex((r) => r.checked) === -1
        ? issueVisibility.PUBLIC.value
        : lang[issueVisibility.RESTRICTED.label];

    this.setState({ roleDefinitions, visibility });
  };

  onChangeVisibility = (visibility) => {
    const roleDefinitions = this.state.roleDefinitions.map((r) => {
      return { ...r, checked: false };
    });

    this.setState({ visibility, roleDefinitions });
  };

  onDeleteComponent = (e, guid) => {
    const elements = [...this.state.elements];
    const index = elements.findIndex((e) => e.ifcGuid === guid);
    if (index > -1) {
      elements.splice(index, 1);
    }

    this.setState({ elements });
  };

  renderElements() {
    return this.state.elements.map((element) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{element.name}</div>
          {!this.state.addComponent && (
            <FontAwesomeIcon
              icon={"times-circle"}
              style={{ color: "#666666" }}
              onClick={(e) => this.onDeleteComponent(e, element.ifcGuid)}
            />
          )}
        </div>
      );
    });
  }

  renderViewpoints() {
    return this.state.viewpoints.map((viewpoint) => {
      const imageId = viewpoint.thumbnailImageId || viewpoint.imageId;
      return (
        <Image
          id={imageId}
          deleteViewpoint={this.handleDeleteViewpoint}
          deletable={true}
        />
      );
    });
  }

  renderRoleDefinitions = () => {
    return this.state.roleDefinitions.map((r) => {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 5px",
          }}
          onClick={() => this.checkBoxChanged(r.value)}
        >
          <input
            type="checkbox"
            checked={r.checked}
            onChange={() => this.checkBoxChanged(r.value)}
          />
          <label style={{ margin: "0 3px" }}>{r.label}</label>
        </div>
      );
    });
  };

  render() {
    const lang = window.isGerman ? de : en;
    const {
      isNew,
      title,
      users,
      assigners,
      reviewer,
      disciplines,
      discipline,
      priority,
      buildings,
      building,
      floors,
      floor,
      workStages,
      workPhase,
      visibilities,
      visibility,
      type,
      description,
      dueDate,
      startDate,
      elements,
      viewpoints,
      addComponent,
      isEmptyDesc,
      isEmptyTitle,
      isEmptyAssigners,
      tags,
      issueTags,
      statuses,
      status,
      priorities,
      types,
    } = this.state;

    return (
      <div className="editor_container">
        <div className="editor_header">
          <h2>{isNew ? lang["NewIssue"] : lang["EditIssue"]}</h2>
        </div>
        <div className="editor_body scrollbar">
          <label
            htmlFor="title"
            style={isEmptyTitle ? { color: "red" } : { color: "black" }}
          >
            {lang["Name"]}
          </label>
          <input
            type="text"
            id="title"
            defaultValue={title}
            onInput={this.handleChangeTitle}
          ></input>
          <label>{lang["StartDate"]}</label>
          <VisoDatePicker
            value={startDate}
            onChange={this.handleChangeStartDate}
            isClearable={true}
          />
          <label>{lang["DueDate"]}</label>
          <VisoDatePicker
            value={dueDate}
            onChange={this.handleChangeDueDate}
            isClearable={true}
          ></VisoDatePicker>
          <label style={isEmptyDesc ? { color: "red" } : { color: "black" }}>
            {lang["Explain"]}
          </label>
          <textarea
            onChange={this.handleChangeDesc}
            defaultValue={description}
          ></textarea>
          <label
            style={isEmptyAssigners ? { color: "red" } : { color: "black" }}
          >
            {lang["AssignWorker"]}
          </label>
          <VisoSelect
            id="assign"
            options={users}
            defaultValue={assigners}
            onChange={this.handleChangeAssigner}
            isMultiSelect={true}
          />
          <label htmlFor="reviewer">{lang["AssignReviewer"]}</label>
          <VisoSelect
            id="reviewer"
            options={users}
            defaultValue={reviewer}
            onChange={this.handleChangeReviewer}
            isClearable={true}
          ></VisoSelect>
          <label>{lang["Discipline"]}</label>
          {
            <VisoSelect
              options={disciplines}
              defaultValue={discipline}
              onChange={this.handleChangeDiscipline}
              isMultiSelect={true}
            ></VisoSelect>
          }
          <label>{lang["Building"]}</label>
          {
            <VisoSelect
              options={buildings}
              defaultValue={building}
              onChange={this.handleChangeBuilding}
            ></VisoSelect>
          }
          <label>{lang["Floor"]}</label>
          {
            <VisoSelect
              options={floors}
              defaultValue={floor}
              onChange={this.handleChangeFloor}
            ></VisoSelect>
          }
          <label>{lang["Workstage"]}</label>
          {
            <VisoSelect
              options={workStages}
              defaultValue={workPhase}
              onChange={this.handleChangeWorkStage}
            />
          }
          <label>{lang["Priority"]}</label>
          {priorities && (
            <VisoSelect
              options={priorities}
              defaultValue={priority}
              onChange={this.handleChangePriority}
            ></VisoSelect>
          )}
          <label>{lang["Type"]}</label>
          {types && (
            <VisoSelect
              options={types}
              defaultValue={type}
              onChange={this.handleChangeType}
            ></VisoSelect>
          )}
          <label>{lang["Tags"]}</label>
          <VisoSelect
            options={tags}
            defaultValue={issueTags}
            onChange={this.handleChangeTags}
            isMultiSelect={true}
          />
          <label>{lang["Status"]}</label>
          <VisoSelect
            options={statuses}
            defaultValue={status}
            onChange={this.handleChangeStatus}
          />
          <label>{lang["Visibility"]}</label>
          <Select
            suffixIcon={<CaretDownFilled />}
            value={visibility}
            onChange={this.onChangeVisibility}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                {this.renderRoleDefinitions()}
              </div>
            )}
          >
            {visibilities.map((v) => (
              <Option value={v.value}>{v.label}</Option>
            ))}
          </Select>
          <button className="visoplan_button" onClick={this.handleAddComponent}>
            {addComponent ? lang["Finish"] : lang["AddComponent"]}
          </button>
          <div className="editor_elements scrollbar">
            {elements && this.renderElements()}
          </div>
          <button className="visoplan_button" onClick={this.handleAddViewpoint}>
            {lang["AddViewpoint"]}
          </button>
          <div
            className="viewpoint_list scrollbar"
            style={{ minHeight: "110px" }}
          >
            {viewpoints && this.renderViewpoints()}
          </div>
        </div>
        <div className="editor_footer">
          <button className="visoplan_button" onClick={this.handleCancle}>
            {lang["Cancel"]}
          </button>
          <button className="visoplan_Actbutton" onClick={this.handleSave}>
            {lang["SaveIssue"]}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
    selectedIssue: state.detail.selectedIssue,
    viewpoints: state.viewpoint.viewpoints,
    user: state.user.currentUser,
    snapshotFlag: state.viewpoint.snapshotFlag,
    snapshotId: state.viewpoint.snapshotId,
    disciplines: state.metaData.disciplines,
    buildings: state.metaData.buildings,
    floors: state.metaData.floors,
    editResult: state.editReport.result,
    priorities: state.issueDescriptor.priorities,
    statuses: state.issueDescriptor.statuses,
    types: state.issueDescriptor.types,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postImage: (imageId) => {
      dispatch(viewpointActions.postImage(imageId));
    },
    clearSnapshot: () => {
      dispatch(viewpointActions.clearSnapshot());
    },
    createIssue: (issue, viewpoints) => {
      dispatch(editIssueActions.createIssue(issue, viewpoints));
    },
    updateIssue: (issue, deletedIds, viewpoints) => {
      dispatch(editIssueActions.updateIssue(issue, deletedIds, viewpoints));
    },
    resetPageState: () => {
      dispatch(editIssueActions.resetPageState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueEdit);
